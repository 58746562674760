<template>
  <v-container>
    <subject-list v-if="show_list" :br_code="branch_code" @search="br_code=null"/>
    <search_form
        v-if="show_search"
        office_selection_label="Select a branch"
        :office_url="office_urls.branch.list"
        key="cib_search"
        title="Search CIB by branch"
        @done_event="handle_cib_search_event"
    />
    <manual_subject_list v-if="show_manual_cib" :br_code="$store.state.branch_data.id"/>
  </v-container>
</template>

<script>
import SubjectList from "@/components/cib_components/subjects_sub_component/subject_list";
import search_form from "@/components/common_components/search_form.vue";
import {user_urls} from "@/constants/user_urls";
import manual_subject_list from "@/components/cib_components/subjects_sub_component/manual_subject_list.vue";


export default {
  name: "Subjects",
  components: {
    SubjectList, search_form,
    manual_subject_list
  },
  computed: {
    show_list() {
      return this.$route.name === 'cib_subjects' && this.$store.getters.permissionCheckers('cib.view_cibsubjects') && this.br_code
    },
    show_manual_cib() {
      return this.$route.name === 'BorrowerWithoutCib'
    },
    show_search() {
      return !this.br_code && this.$route.name !== 'BorrowerWithoutCib'
    },
    branch_code() {
      return this.br_code
    },
    office_urls() {
      return user_urls.office
    }
  },
  data: () => ({
    br_code: null
  }),
  methods: {
    handle_cib_search_event(value) {
      this.br_code = value
    },
  }
}
</script>

<style scoped>

</style>