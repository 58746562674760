<template>
  <v-dialog
      max-width="70%"
      v-model="dialog"
      @input="load_data"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading" :color="color" :small="small">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="subject_name" name="Subject's Name" rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      v-model="item.subject_name"
                      label="Subject's Name*"
                      hint="Individual/Proprietor/Company 's Name"
                      dense
                      clearable
                      auto-grow
                      rows="1"
                      counter
                      maxlength="256"
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>

              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="owner_type" name="Owner Type"
                                    rules="objectNotEmpty:owner_type|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Owner Type*"
                      hint="Owner Type"
                      :items="owner_types"
                      :item-text="(item)=>item.name"
                      item-value="code"
                      v-model="item.owner_type"
                      requiredkl
                      autocomplete="nope"
                      :loading="owner_types_loading"
                      dense
                  >
                  </v-autocomplete>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="eager" vid="nid" name="NID" rules=""
                                    v-slot="{ errors }">
                  <v-text-field
                      v-model="item.nid"
                      label="NID/Other ID document Number"
                      hint="NID/Other ID document Number"
                      dense
                      clearable
                      auto-grow
                      rows="1"
                      counter
                      maxlength="256"
                  ></v-text-field>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import {user_urls} from "@/constants/user_urls";
import cib_urls from "@/constants/cib_urls";

export default {
  name: "addManualOwnerForm",
  props: ['icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading', 'color', 'small', 'obj', 'cib'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    computed_passed_item: {
      get() {
        return JSON.parse(JSON.stringify(this.obj))
      },
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    owner_types: [],
    owner_types_loading: false,
    item: {},
    non_field_errors: [],
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      let data = {
        ...this.item,
        br_code: this.$store.state.branch_data.id,
        cib: this.cib.id
      }
      await axios.post(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.subject_name),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                non_field: error.response.data.errors.non_field_errors,
                subject_name: error.response.data.errors.subject_name,
                owner_type: error.response.data.errors.owner_type,
                nid: error.response.data.errors.nid,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      } else if (this.action === 'edit') {
        this.edit_item()
      }
    },
    async edit_item() {
      this.axios_conf
      let conf = {
        headers: {
          multi: true
        }
      }
      let data = {
        ...this.item,
        owner_type: this.item.owner_type.id,
        br_code: this.$store.state.branch_data.id,
        cib: this.cib.id
      }
      await axios.patch(this.url, data, conf)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$refs.vform.resetValidation()
            this.$refs.vform.reset()
            this.$emit('done_event', resp.data.items)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.subject_name),
              status: true
            })
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                non_field: error.response.data.errors.non_field_errors,
                subject_name: error.response.data.errors.subject_name,
                owner_type: error.response.data.errors.owner_type,
              });
            }
          })
      this.submit_loading = false
    },
    get_owner_type() {
      this.axios_conf
      let conf = {
        headers: {multi: true}
      }
      this.owner_types_loading = true
      axios.get(cib_urls.owner_types.list, conf).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.owner_types = resp.data.items
        this.owner_types_loading = false
      }).catch((err) => {
        if (err.response) {
          if (err.response.status >= 400) {
            this.axios_conf
            axios.post(user_urls.user.logout)
            this.$router.replace({name: 'Login'})
            this.$store.commit('setLogout')
            return 0
          }
          this.$store.commit('setJWT', err.response.data.key)
        }
        this.$store.commit("update_snackbar", {text: "Server Error", status: true})
        this.$refs.observer.setErrors({
          owner_type: ["Server Error"],
        });
      })
    },
    load_data() {
      this.get_owner_type()
      if (this.obj) {
        this.item = {
          ...this.obj
        }
        console.log(this.item)
      }
    }
  },
  // watch: {
  //   court_type: {
  //     immediate: true,
  //     deep: true,
  //     handler(newValue, oldValue) {
  //       if (newValue && this.dialog) {
  //         this.get_courts(newValue)
  //         this.get_purposes(newValue)
  //         if (newValue !== oldValue) {
  //           this.item.court = null
  //           this.item.purpose = null
  //         }
  //       }
  //     }
  //   }
  // },
  mounted() {
    // this.load_data()
  }
}
</script>

<style>
div.v-list-item__content > div.v-list-item__title {
  overflow-wrap: break-word;
}
</style>