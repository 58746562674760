<template>
  <v-container v-if="has_view_permission" class="px-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        sort-by="subject_code"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        name="subjects_table"
        :search="search"
        show-expand
        single-expand
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          Borrowers without CIB
          <v-spacer></v-spacer>
          <addManualCibForm v-if="add_button" activator_name="Add New Borrowers"
                            form_title="Add New Borrower"
                            :url="urls.add"

                            activator_icon="mdi-plus" action="add"
                            tooltip_text="Add New Borrower"
                            big_btn="true" color="orange" @done_event="borrower_added"
                            snackbar_text="New borrower has been added"/>
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
          ></v-text-field>
        </v-card-title>
      </template>
      <template v-slot:item.action="{ item }">
        <addManualCibForm v-if="edit_button" activator_name="Edit Borrowers"
                          form_title="Edit Borrower"
                          :url="urls.edit + '?cib_id=' + item.id"
                          activator_icon="mdi-pencil" action="edit"
                          tooltip_text="Edit Borrower"
                          small=True color="orange" @done_event="borrower_edit"
                          :snackbar_text="`${item.subject_code} borrower has been updated`"
                          :obj="item"
                          :key="item.id + '_add_btn'"
        />
        <delete_dialog v-if="delete_button" @form_status="borrower_edit" :passed_item="item"
                       :dialogue_text="`Are you sure you want to delete borrower# ${item.subject_code}?`"
                       :url="`${urls.delete}?cib_id=${item.id}`"
                       icon="mdi-delete" tooltip_text="Delete"
                       action="delete" :key="item.id + 'delete_btn'"></delete_dialog>
      </template>
      <template v-slot:expanded-item="{ headers,item}">
        <td :colspan="headers.length" :key="item.id">
          <manual-owner-list :cib="item" :key="item.id + '_owner'"/>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>


import axios from "axios";
import CIB_urls from "@/constants/cib_urls";
import cib_perms from "@/constants/permissions/cib_perms";
import addManualCibForm from "@/components/cib_components/subjects_sub_component/addManualCibForm.vue";
import delete_dialog from "@/components/user_components/user-sub_component/delete_dialog.vue";
import ManualOwnerList from "@/components/cib_components/subjects_sub_component/manual_owner_list.vue";
// import mamla_summary_details from "@/components/dashboard/mamla_summary_details.vue";

export default {
  name: "manualSubjectList",
  props: ["br_code"],
  components: {
    ManualOwnerList,
    // mamla_summary_details,
    delete_dialog,
    addManualCibForm
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    add_button() {
      return this.$store.getters.permissionCheckers(cib_perms.manual.add)
    },
    edit_button() {
      return this.$store.getters.permissionCheckers(cib_perms.manual.edit)
    },
    delete_button() {
      return this.$store.getters.permissionCheckers(cib_perms.manual.delete)
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers(cib_perms.manual.view)
    },
  },
  data: () => ({
    table_items: [],
    skeleton_loader_attrs: {
      boilerplate: true,
    },
    show_snackbar: false,
    snackbar_text: '',
    urls: CIB_urls.manual,

    table_loading: false,
    reload_btn: false,
    search: '',
    column_headers: [
      {
        text: 'Subject Code',
        align: 'center',
        filterable: true,
        value: 'subject_code',
      },
      {
        text: 'Subject Name',
        align: 'center',
        filterable: true,
        value: 'subject_name',
      },
      {
        text: 'Subject Type',
        align: 'center',
        filterable: true,
        value: 'subject_type',
      },
      {
        text: 'Business Type',
        align: 'center',
        filterable: true,
        value: 'business_type',
      },
      {
        text: 'Branch',
        align: 'center',
        filterable: true,
        value: 'br_code',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'action',
      },
      {
        text: 'Owner Detailsk ',
        align: 'center',
        filterable: false,
        value: 'data-table-expand',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(`${this.urls.list}?br_code=${this.br_code}`, config).then((resp) => {
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      })
    },
    borrower_added() {
      this.get_lists()
    },
    borrower_edit() {
      this.get_lists()
    },
  },
  mounted() {
    this.get_lists()
  },
  watch: {}
}
</script>

<style>
div[name="lawyer_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>