<template>
  <v-dialog v-model="dialog" max-width="30%">
    <template v-slot:activator="{ on:dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-2">
            {{ icon }}
          </v-icon>
        </template>
        <span>{{ tooltip_text }}</span>
      </v-tooltip>
    </template>
    <v-card class="warning justify-center">
      <v-card-title class="text-h5 justify-center"><span
          class="text-center">{{ dialogue_text }}</span>
      </v-card-title>
      <v-card-text v-if="errors">
        <span class="red--text">*{{ errors }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog=false">Cancel</v-btn>
        <v-btn color="blue darken-1" :loading="submit_loading" text @click="handle_submit">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import index from "vuex";

export default {
  name: "delete_dialog",
  props: ['passed_item', 'dialogue_text', 'icon', 'tooltip_text', 'url', 'action'],
  computed: {
    index() {
      return index
    },
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      axios.defaults.headers.common['multi'] = `true`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    errors: null
  }),
  methods: {
    async handle_submit() {
      this.axios_conf
      this.submit_loading = true

      if (this.action === 'delete') {
        await axios.delete(this.url)
            .then((resp) => {
              this.$store.commit('setJWT', resp.data.key)
              this.dialog = false
              this.$emit('form_status', true)
              this.$emit('snackbar_st', `User#${this.passed_item.username} is deleted`)
            })
            .catch((error) => {
              this.submit_loading = false
              this.errors = error.response.data.errors.non_field
              this.$store.commit('setJWT', error.response.data.key)
            })
      } else if (this.action === 'post') {
        await axios.post(this.url)
            .then((resp) => {
              this.$store.commit('setJWT', resp.data.key)
              this.dialog = false
              this.$emit('form_status', true)
              this.$emit('snackbar_st', `User#${this.passed_item.username}'s password reset is successful`)
            })
            .catch((error) => {
              this.submit_loading = false
              console.log(error)
            })
      }
      this.submit_loading = false
    },
  },
  // watch: {
  //   zone(val) {
  //     return this.circle = val
  //   },
  // }
}
</script>

<style scoped>

</style>