<template>
  <v-container class="align-center justify-center">
    <v-card
        elevation="9"
        outlined
        shaped
        tile
        :loading="loading"
        max-width="800"
        color="amber accent-1"
        class="justify-center"
    >
      <v-card-title class="align-center justify-center">{{ title }}</v-card-title>
      <v-card-text>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
          <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
            <v-card class="light-blue lighten-3">
              <v-card-text>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                        rules=""
                                        v-slot="{ errors }">
                      <ul v-for="err in errors" :key="err">
                        <li>
                          <span class="red--text">{{ err }}</span>
                        </li>
                      </ul>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider mode="passive" vid="branch" name="Branch name"
                                        rules="objectNotEmpty:code|required"
                                        v-slot="{ errors }">
                      <v-select :label="office_selection_label + '*'" v-model="office_code"
                                hint="The office you want to search cib subjects for"
                                :items="branch" item-text="name" clearable
                                item-value="code" item-color="primary" :loading="branch_loading"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-text-field v-model="search" placeholder="Search"
                                            @input="searchBranch"></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>

                      </v-select>
                      <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-5"
                    text
                    type="submit"
                    :loading="loading"
                    elevation="9"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>

</style>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import axios from "axios";
import "@/validations/office_validations"

export default {
  name: "search-form",
  props: ['title', 'office_url', 'office_selection_label'],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      axios.defaults.headers.common['multi'] = true
      return 0
    },
  },
  data: () => ({
    loading: false,
    office_code: null,
    branch: [],
    branchCopy: [],
    search: "",
    report_name: [],
    branch_loading: false,
  }),
  methods: {
    handle_submit() {
      this.loading = true
      this.$emit('done_event', this.office_code)
      let office = null
      for (let x in this.branchCopy) {
        if (this.branchCopy[x].code === this.office_code) {
          office = this.branchCopy[x]
          break
        }
      }
      this.$emit("office_value", office)
    },
    get_branches() {
      this.axios_conf
      this.branch_loading = true
      axios.get(this.office_url)
          .then((resp) => {
            this.branch = resp.data.branches || resp.data.zones || resp.data.circles
            this.branchCopy = [...this.branch]
            this.$store.commit('setJWT', resp.data.key)
            this.branch_loading = false
          })
    },
    searchBranch() {
      if (!this.search) {
        this.branch = this.branchCopy;
      }
      this.branch = this.branchCopy.filter((br) => {
        return br.code.toString() === this.search || br.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  mounted() {
    this.get_branches()
  }
}

</script>