<template>
  <v-container v-if="has_view_permission" class="px-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        sort-by="id"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        name="subjects_table"
        :search="search"
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          CIB Subjects
          <v-spacer></v-spacer>
          <v-btn v-if="update_button" :loading="reload_btn" @click="update_subject_table" class="ma-1">
            <v-icon>
              mdi-update
            </v-icon>
            Update CIB Subjects
          </v-btn>
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
          <v-btn :loading="reload_btn" @click="handle_another_branch" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Search For another Branch
          </v-btn>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
          ></v-text-field>
        </v-card-title>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>


import axios from "axios";
import CIB_urls from "@/constants/cib_urls";

export default {
  name: "SubjectList",
  props: ["br_code"],
  components: {},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('cib.view_cibsubjects')
    },
    update_button() {
      return this.$store.getters.permissionCheckers('cib.change_cibsubjects')
    },
  },
  data: () => ({
    table_items: [],
    skeleton_loader_attrs: {
      boilerplate: true,
    },
    show_snackbar: false,
    snackbar_text: '',
    list_url: CIB_urls.subjects.list,
    add_url: CIB_urls.subjects.add,
    table_loading: false,
    reload_btn: false,
    search: '',
    column_headers: [
      {
        text: 'Subject Code',
        align: 'center',
        filterable: true,
        value: 'subject_code',
      },
      {
        text: 'Subject Name',
        align: 'center',
        filterable: true,
        value: 'subject_name',
      },
      {
        text: 'Subject Type',
        align: 'center',
        filterable: true,
        value: 'subject_type',
      },
      {
        text: 'Business Type',
        align: 'center',
        filterable: true,
        value: 'business_type',
      },
      {
        text: 'Branch',
        align: 'center',
        filterable: true,
        value: 'br_code',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(this.list_url.replace("{br_code}", this.br_code), config).then((resp) => {
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      })
    },
    handle_another_branch() {
      this.$emit("search", true)
    },
    update_subject_table() {
      this.axios_conf
      let config = {
        headers: {
          multi: 'false'
        }
      }
      this.reload_btn = true
      axios.post(this.add_url, config).then((resp) => {
        this.$store.commit('setJWT', resp.data.key)
        this.$store.commit("update_snackbar", {text: "Process to Add/Update Subjects Started", status: true})
        this.reload_btn = false
      })
    }
  },
  mounted() {
    this.get_lists()
  },
  watch: {}
}
</script>

<style>
div[name="lawyer_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>