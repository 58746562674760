<template>
  <v-container v-if="has_view_permission" class="px-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        sort-by="subject_code"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        name="subjects_table"
        :search="search"
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          Owners
          <v-spacer></v-spacer>
          <addManualOwnerForm v-if="add_button" activator_name="Add New Owner"
                              form_title="Add New Owner"
                              :url="urls.add"
                              :cib="cib"
                              activator_icon="mdi-plus" action="add"
                              tooltip_text="Add New Owner"
                              big_btn="true" color="orange" @done_event="owner_added"
                              snackbar_text="New Owner has been added"/>
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-3"
          ></v-text-field>
        </v-card-title>
      </template>
      <template v-slot:item.action="{ item }">
        <addManualOwnerForm v-if="edit_button" activator_name="Edit Owner"
                            form_title="Edit Owner"
                            :url="urls.edit + '?owner_id=' + item.id"
                            activator_icon="mdi-pencil" action="edit"
                            tooltip_text="Edit Owner"
                            small=True color="orange" @done_event="owner_edit"
                            :snackbar_text="`${item.subject_code} Owner has been updated`"
                            :obj="item"
                            :cib="cib"
        />
        <delete_dialog v-if="delete_button" @form_status="owner_edit" :passed_item="item"
                       :dialogue_text="`Are you sure you want to delete Owner# ${item.subject_code}?`"
                       :url="`${urls.delete}?owner=${item.id}`"
                       icon="mdi-delete" tooltip_text="Delete"
                       action="delete"></delete_dialog>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>


import axios from "axios";
import CIB_urls from "@/constants/cib_urls";
import cib_perms from "@/constants/permissions/cib_perms";
import addManualOwnerForm from "@/components/cib_components/subjects_sub_component/addManualOwnerForm.vue";
import delete_dialog from "@/components/user_components/user-sub_component/delete_dialog.vue";
// import mamla_summary_details from "@/components/dashboard/mamla_summary_details.vue";

export default {
  name: "manualOwnerList",
  props: ["br_code", "cib"],
  components: {
    // mamla_summary_details,
    delete_dialog,
    addManualOwnerForm
  },
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    add_button() {
      return this.$store.getters.permissionCheckers(cib_perms.manual_owner.add)
    },
    edit_button() {
      return this.$store.getters.permissionCheckers(cib_perms.manual_owner.edit)
    },
    delete_button() {
      return this.$store.getters.permissionCheckers(cib_perms.manual_owner.delete)
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers(cib_perms.manual_owner.view)
    },
  },
  data: () => ({
    table_items: [],
    skeleton_loader_attrs: {
      boilerplate: true,
    },
    show_snackbar: false,
    snackbar_text: '',
    urls: CIB_urls.manual_owner,

    table_loading: false,
    reload_btn: false,
    search: '',
    column_headers: [
      {
        text: 'Subject Code',
        align: 'center',
        filterable: true,
        value: 'subject_code',
      },
      {
        text: 'Subject Name',
        align: 'center',
        filterable: true,
        value: 'subject_name',
      },
      {
        text: 'Owner Type',
        align: 'center',
        filterable: true,
        value: 'owner_type.name',
      },
      {
        text: 'NID/Passport',
        align: 'center',
        filterable: true,
        value: 'nid',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'action',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'true'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(`${this.urls.list}?cib_id=${this.cib.id}`, config).then((resp) => {
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      })
    },
    owner_added() {
      this.get_lists()
    },
    owner_edit() {
      this.get_lists()
    },
  },
  mounted() {
    this.get_lists()
  },
  watch: {}
}
</script>

<style>
div[name="lawyer_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>