export const user_urls = {
    user: {
        logout: "user/logout",
        list: "user/"
    },
    office: {
        branch: {
            list: "user/branch_office"
        },
        zone: {
            list: "user/zonal_office"
        },
        circle: {
            list: "user/circle_office"
        }
    }
};
