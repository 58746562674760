const CIB_urls = {
    loan_types: {
        list: "cib/loantypes/",
        add: "cib/loantypes/",
        get: "cib/loantypes/{id}/",
        edit: "cib/loantypes/{id}/",
        delete: "cib/loantypes/{id}/",
    },
    owner_types: {
        list: "cib/ownertypes/",
        add: "cib/ownertypes/",
        get: "cib/ownertypes/{id}/",
        edit: "cib/ownertypes/{id}/",
        delete: "cib/ownertypes/{id}/",
    },
    subjects: {
        list: "cib/subjects/{br_code}/",
        select: "cib/subjects?subject_code={subject_code}",
        add: "cib/subjects/",
    },
    loan_data: {
        list: "cib/loan_data/",
        add: "cib/loan_data/",
    },
    manual: {
        list: "cib/manual_cib/",
        add: "cib/manual_cib/",
        edit: "cib/manual_cib/",
        delete: "cib/manual_cib/",
    },
    business_types: {
        list: "cib/business_type/"
    },
    subject_types: {
        list: "cib/subject_type/"
    },
    manual_owner: {
        list: "cib/manual_owner/",
        add: "cib/manual_owner/",
        edit: "cib/manual_owner/",
        delete: "cib/manual_owner/",
    }
};

export default CIB_urls;
