const cib_perms = {
    manual: {
        view: "cib.view_manual_entries",
        add: "cib.add_manual_entries",
        edit: "cib.edit_manual_entries",
        delete: "cib.delete_manual_entries",
    },
    manual_owner: {
        view: "cib.view_manual_entries",
        add: "cib.add_manual_entries",
        edit: "cib.edit_manual_entries",
        delete: "cib.delete_manual_entries",
    }

}
export default cib_perms;